import { AnchorHTMLAttributes, MouseEventHandler } from 'react';
import { Link as RouterLink, To } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { checkIsComponentWithIconChild, cn, VariantProps } from '@utils';
import { buttonVariants } from '@utils/variants';

import { Icon } from '@components';

type BaseLinkProps = {
  theme?: boolean;
  disabled?: boolean;
  className?: string;
  icon?: IconProp;
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

type RouterLinkProps = {
  to: To;
  replace?: boolean;
  external?: false;
  state?: any;
};

type AnchorLinkProps = {
  external: true;
  blank?: boolean;
  to: string;
};

type Props = AnchorHTMLAttributes<HTMLAnchorElement> &
  VariantProps<typeof buttonVariants> &
  BaseLinkProps &
  (RouterLinkProps | AnchorLinkProps);

export const Link = ({
  to,
  children,
  theme,
  disabled = false,
  className,
  variant,
  external = false,
  icon,
  ...props
}: Props) => {
  const isWithIcon = !!icon || checkIsComponentWithIconChild(children);
  const classNames = theme
    ? cn(className, {
        'pointer-events-none': disabled,
      })
    : cn(buttonVariants({ variant, withIcon: isWithIcon, className }), {
        'pointer-events-none': disabled,
      });

  if (external) {
    const { blank, ...anchorProps } =
      props as AnchorHTMLAttributes<HTMLAnchorElement> &
        Pick<AnchorLinkProps, 'blank'>;
    const target = blank ? '_blank' : '';
    const linkOptions = {
      ...anchorProps,
      className: classNames,
      target,
      href: to,
      disabled,
    } as AnchorHTMLAttributes<HTMLAnchorElement>;

    return (
      // eslint-disable-next-line react/no-invalid-html-attribute
      <a {...linkOptions} rel="noreferrer nooperer">
        {children}
      </a>
    );
  }

  const options = {
    ...props,
    className: classNames,
    to,
    disabled,
  };

  return (
    <RouterLink {...options}>
      <Icon icon={icon} className="text-[20px]" />
      {children}
    </RouterLink>
  );
};
