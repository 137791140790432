import { createContext, useCallback, useMemo } from 'react';
import { useImmer } from 'use-immer';

import { noop } from '@utils';

export const AppConstructorNavigationContext = createContext<{
  navigationTitles: string[];
  registerNavigationTitle: (navigation: string) => void;
}>({ navigationTitles: [], registerNavigationTitle: noop });

type Props = {
  children?: React.ReactNode;
};

export const AppConstructorNavigationProvider = ({ children }: Props) => {
  /**
   * State is array of `title`
   * Title is needed to display, but has to converted as "formatted model name"
   */
  const [titles, setTitles] = useImmer<string[]>([]);

  const handleNavigationTitleRegister = useCallback(
    (title: string) => {
      setTitles((titles) => {
        if (!titles.includes(title)) {
          titles.push(title);
        }
      });
    },
    [setTitles],
  );

  const value = useMemo(
    () => ({
      navigationTitles: titles,
      registerNavigationTitle: handleNavigationTitleRegister,
    }),
    [titles, handleNavigationTitleRegister],
  );

  return (
    <AppConstructorNavigationContext.Provider value={value}>
      {children}
    </AppConstructorNavigationContext.Provider>
  );
};
