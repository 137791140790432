import { useCallback, useEffect, useState } from 'react';
import { faRectangleTerminal } from '@fortawesome/pro-solid-svg-icons';

import { App, DedicatedAppName } from '@typings';
import { DEDICATED_APP_NAME } from '@constants';
import { toast } from '@features';
import { createJob as uninstallDedicatedApp, getStorageApps } from '@services';
import {
  clusterContextSelector,
  contextNamesSelector,
  viewTypeSelector,
} from '@selectors';
import { useSelector } from '@hooks';
import { usePowerlessResourcePresetName } from '@hooks/job';
import {
  as,
  getInstallingAppImage,
  invariant,
  path,
  toastifyResponseError,
} from '@utils';
import { dedicatedAppLogo } from '@content';

import { Button, Icon, Link, Render, Table } from '@components';
import { Job } from '@components/Ui';
import { AppInstalledDedicatedItem } from '@components/Ui/Apps';

type UninstallAppPayload = {
  /**
   * Used for setting killing app ID
   */
  appId: string;
  entrypoint?: string;
  command?: string;
};

export const LegacyAppsPage = () => {
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);
  const cluster = useSelector(clusterContextSelector);
  const { isGridView } = useSelector(viewTypeSelector);

  const { powerlessPresetName } = usePowerlessResourcePresetName();

  /**
   * Installed dedicated app names
   */
  const [installedDedicatedApps, setInstalledDedicatedApps] = useState<
    App.DedicatedModel[]
  >([]);
  const [killingAppId, setKillingAppId] = useState('');

  const { storageUrl } = as.c(cluster);

  const getDedicatedApps = useCallback(async () => {
    if (!projectName) {
      return;
    }

    try {
      const dedicatedApps = await getStorageApps({
        organizationName,
        projectName,
        storageUrl,
      });

      setInstalledDedicatedApps(dedicatedApps);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
      /**
       * Continue regardless error
       */
    }
  }, [organizationName, projectName, storageUrl]);

  useEffect(() => {
    const INTERVAL_TIMEOUT = 10_000;

    getDedicatedApps();

    const intervalId = setInterval(() => {
      getDedicatedApps();
    }, INTERVAL_TIMEOUT);

    return () => {
      clearInterval(intervalId);
    };
  }, [getDedicatedApps]);

  const handleDedicatedAppUninstall = async ({
    appId,
    entrypoint,
    command,
  }: UninstallAppPayload) => {
    try {
      setKillingAppId(appId);

      invariant(clusterName);
      invariant(projectName);

      await uninstallDedicatedApp({
        passConfig: true,
        organizationName,
        clusterName,
        projectName,
        presetName: powerlessPresetName,
        entrypoint,
        command,
        name: `${appId}-uninstall`,
        image: getInstallingAppImage('ghcr.io/neuro-inc/app-deployment'),
      });

      toast.success('The app is being uninstalled');
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setKillingAppId('');
    }
  };

  const isDedicatedAppHasCommand = (name: string) =>
    Object.values(DEDICATED_APP_NAME)
      .filter((name) => name !== DEDICATED_APP_NAME.POSTGRESQL)
      /**
       * todo: fix type
       */
      // @ts-ignore
      .includes(name as DedicatedAppName);

  const makeDedicatedApp = ({
    name,
    status,
    appType,
    appId,
    appUrl,
  }: App.DedicatedModel) => {
    const isUninstalling = killingAppId === name;
    const payload: UninstallAppPayload = {
      appId: name,
      command: isDedicatedAppHasCommand(appType) ? `delete ${name}` : undefined,
      // @ts-ignore
      entrypoint: [DEDICATED_APP_NAME.POSTGRESQL].includes(appType)
        ? `./entrypoints/pgo.sh delete ${DEDICATED_APP_NAME.POSTGRESQL} ${name}`
        : undefined,
    };

    const logo = dedicatedAppLogo[appType];

    const onUninstall = () => handleDedicatedAppUninstall(payload);

    if (isGridView) {
      return (
        <AppInstalledDedicatedItem
          key={appId}
          isUninstalling={isUninstalling}
          appName={name}
          appType={appType}
          status={status}
          appId={appId}
          appUrl={appUrl}
          onAppUninstall={onUninstall}
        />
      );
    }

    return (
      <Table.Row key={appId}>
        <Table.Cell className="flex-row items-center justify-start gap-2">
          <div className="flex h-5 w-5 shrink-0 items-center justify-center rounded-md bg-black p-0.5 text-[12px] text-white">
            <Render if={!logo}>
              <Icon icon={faRectangleTerminal} />
            </Render>
            <Render if={logo}>
              <img src={logo} alt="" />
            </Render>
          </div>
          <p className="truncate">{name}</p>
        </Table.Cell>
        <Table.Cell>
          <Job.Status status={status} />
        </Table.Cell>
        <Table.Cell className="flex-row justify-end gap-2">
          <Render if={appUrl}>
            <Button className="h-8 rounded-md px-2 py-1 text-footnote-large">
              <Link theme external blank to={appUrl!}>
                Open
              </Link>
            </Button>
          </Render>
          <Button
            disabled={!appId}
            className="h-8 rounded-md px-2 py-1 text-footnote-large"
          >
            <Link theme external blank to={path.job(appId)}>
              Details
            </Link>
          </Button>
          <Button
            loading={isUninstalling}
            variant="error"
            className="h-8 rounded-md px-2 py-1 text-footnote-large"
            onClick={onUninstall}
          >
            Uninstall
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  };

  if (isGridView) {
    return (
      <div className="grid grid-cols-3 gap-x-10 gap-y-8 hdp:grid-cols-2">
        {installedDedicatedApps.map(makeDedicatedApp)}
      </div>
    );
  }

  return (
    <Table className="w-full auto-rows-min grid-cols-[minmax(240px,auto)_minmax(240px,360px)_min-content] overflow-auto">
      <Table.Header>
        <Table.Row>
          <Table.Head>ID / Name</Table.Head>
          <Table.Head>Status</Table.Head>
          <Table.Head className="text-right">Actions</Table.Head>
        </Table.Row>
      </Table.Header>
      <Table.Body>{installedDedicatedApps.map(makeDedicatedApp)}</Table.Body>
      <Table.Body />
    </Table>
  );
};
