import { useParams } from 'react-router-dom';
import { faMessageXmark } from '@fortawesome/pro-thin-svg-icons';

import { PlatformApp } from '@typings';
import { PATH } from '@constants';
import { platformAppsTemplateSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as } from '@utils';

import { Helmet, Link } from '@components';
import { Layout } from '@components/Layouts';
import { EmptyContent } from '@components/Ui';
import { PlatformAppsInstallingConstructor } from '@components/Ui/PlatformApps/PlatformAppsInstallingConstructor';

export const PlatformAppsInstallingPage = () => {
  const { appName } = useParams();

  const template = useSelector(platformAppsTemplateSelector(appName!));

  const { title } = as.o<PlatformApp.Template>(template);

  const formattedTitle = `${title || appName} App`;

  const renderContent = () => {
    if (!template) {
      return (
        <EmptyContent
          variant="layout"
          icon={faMessageXmark}
          title="Something went wrong"
          text="Error occurred on parsing app or schema"
        >
          <Link to={PATH.APPS} className="capitalize">
            Explore apps
          </Link>
        </EmptyContent>
      );
    }

    return (
      <Layout.Content className="flex gap-10">
        <PlatformAppsInstallingConstructor template={template} />
      </Layout.Content>
    );
  };

  return (
    <Layout backTo={PATH.APPS} title={formattedTitle}>
      <Helmet title={formattedTitle} />
      {renderContent()}
    </Layout>
  );
};
