import { Tagged } from 'type-fest';

import { ImageUrl } from './shared';

export type PlatformAppLoader = 'templates' | 'schema' | 'instances';

type TemplateLink = { text: string; url: string };

type TemplateAsset = { type: 'image' | 'video'; url: string };

export enum SchemaField {
  ClusterName = 'clusterName',
  Checkbox = 'enabled',
  Grpc = 'grcp',
}

export type AnyOfEntry = {
  $ref?: string;
} & {
  type: SchemaTypeValue;
};

export type AnyOf = AnyOfEntry[];

export type SchemaTypeValue =
  | 'string'
  | 'object'
  | 'array'
  | 'boolean'
  | 'integer'
  | 'float'
  | 'null';

export type SchemaType = {
  type: SchemaTypeValue;
};

export namespace SchemaValidation {
  export type Required = string[];

  export type Schema = {
    required?: Required;
    anyOf: AnyOf;
  };
}

export namespace SchemaProperty {
  export type Property = {
    title: string;
    description?: string;
    default?: any;
    'x-title'?: string;
    xTitle?: string;
    'x-description'?: string;
    xDescription?: string;
    'x-logo-url'?: string;
    xLogoUrl?: string;
    logoUrl?: string;
    type: SchemaType;
  };

  export type Reference = {
    title: string;
    descripton?: string;
    $ref?: string;
    anyOf?: AnyOfEntry[];
    type: SchemaTypeValue;
  };

  export type Model = Property | Reference;
}

export namespace SchemaDefinition {
  export type DefinitionName = Tagged<'SchemDefinitionName', string>;
  export type ComponentName = Tagged<'SchemaDefinitionComponentName', string>;

  export type Model = SchemaValidation.Schema & {
    title: string;
    properties: Record<ComponentName, SchemaProperty.Model>;
  };

  export type Definitions = Record<DefinitionName, Model>;
}

export namespace PlatformApp {
  export type InputTypeParams = Record<string, string | number>;

  export type InputType = {
    $defs: SchemaDefinition.Definitions;
    properties: Record<string, SchemaProperty.Model>;
  };

  export type InputMetadata = {
    title: string;
    description?: string;
    logoUrl?: string;
  };

  export type OutputType = InputType;

  export type Template = {
    name: string;
    title: string;
    shortDescription: string;
    description: string;
    version: string;
    logo: ImageUrl;
    documentationUrls: TemplateLink[];
    externalUrls: TemplateLink[];
    /**
     * todo: make it as string date format
     */
    pubDate: string;
    assets: TemplateAsset[];
    tags: string[];
    gitUrl: string;
    input: InputType;
    output: InputType;
  };

  export type Instance = {
    id: string;
    name: string;
    displayName: string;
    templateName: string;
    templateVersion: string;
    projectName: string;
    orgName: string;
    state: string;
  };

  export type Schema = {
    /**
     * Protocol name
     * Most likely will be ignored on client side
     */
    title: string;
    $defs: SchemaDefinition.Definitions;
  };

  export type Schemas = Record<string, Schema>;
}

export namespace PlatformAppUiSchema {
  export type NestSchema = {
    title: string;
    description?: string;
    level: number;
    parent?: string;
    type: string;
    fields: string[];
  };

  export type NestResolver = {
    level: number;
    list: NestSchema[];
  };

  export type Model = {
    title: string;
    description?: string;
    type: string;
    const?: string;
    required?: boolean;
    default?: any;
  };

  export type ModelSchema = Record<string, Model>;
}
