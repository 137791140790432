import { useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { AsyncFunction, Job } from '@typings';
import { PATH } from '@constants';
import { useHelmetTitle } from '@hooks';
import { as, capitalize } from '@utils';

import { ChaseSpinner, Helmet, Theme } from '@components';
import { JobDetailsHandleCenter } from '@components/Job';
import { Layout } from '@components/Layouts';
import { JobTabs } from '@components/Ui';

type Props = {
  job: Job.Model | null;
  getJob: AsyncFunction;
};

export const JobLayout = ({ job, getJob }: Props) => {
  const { makeTitle } = useHelmetTitle();

  const [tabTitle, setTabTitle] = useState<Job.Tab>();

  const { name, id: jobId } = as.o<Job.Model>(job);
  const title = makeTitle(name ?? jobId, tabTitle, 'Job', '%p', '%c');

  const handleTabTitleChange = useCallback((tab: Job.Tab) => {
    setTabTitle(capitalize(tab) as Job.Tab);
  }, []);

  const renderContent = () => {
    if (!job) {
      return (
        <Layout.Content className="relative flex justify-center">
          <ChaseSpinner color="black" className="absolute mt-40 h-14 w-14" />
        </Layout.Content>
      );
    }

    return (
      <Layout.Content>
        <JobDetailsHandleCenter job={job} getJob={getJob} />
        <Theme.Container className="relative p-0">
          <JobTabs jobId={jobId} name={name} />
          <Outlet context={{ setTabTitle: handleTabTitleChange }} />
        </Theme.Container>
      </Layout.Content>
    );
  };

  return (
    <Layout title={title} backTo={PATH.JOBS}>
      <Helmet
        title={title}
        description="Monitor and configure your cluster settings with structured resource presets. Ensure optimal performance and resource allocation by accessing detailed cluster information and management tools"
      />
      {renderContent()}
    </Layout>
  );
};
