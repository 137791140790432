import { FieldErrors } from 'react-hook-form';

type NormalizeFormErrors = <Fields extends string>(
  errors?: FieldErrors,
) => { [key in Fields]?: string };

/**
 * Formatting react-hook-form form state errors
 * for best practice using with InputError
 *
 * @example normalizeFormErrors<"email" | "password">(formState.errors);
 * // return { email?: string; password?: string }
 */
export const normalizeFormErrors: NormalizeFormErrors = (errors = {}) =>
  Object.entries(errors).reduce(
    /**
     * todo: resolve type
     */
    // @ts-ignore
    (collection, [name, { message, types }]) => ({
      ...collection,
      [name]: message || types?.message,
    }),
    {},
  );

export function normalizePlatformAppsFormErrors(
  obj: Record<string, any>,
  prefix = '',
): Record<string, any> {
  const errors = Object.entries(obj).reduce(
    (acc, [key, value]) => {
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value) &&
        !('message' in value)
      ) {
        Object.assign(acc, normalizePlatformAppsFormErrors(value, newKey));
      } else {
        acc[newKey] = value?.message;
      }
      return acc;
    },
    {} as Record<string, any>,
  );

  return errors;
}
