import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { PATH } from '@constants';
import { installPlatformApp } from '@services';
import { getPlatformAppsInstances } from '@thunks/platformApps.thunks';
import { contextNamesSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { invariant, normalizeFormErrors, toastifyResponseError } from '@utils';

import { Button, Field, Theme } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  data: z.string().nonempty('Required'),
});

export const PlatformAppsDevInstallPage: React.FC = () => {
  const dispatch = useDispatch();
  const { organizationName, clusterName, projectName } =
    useSelector(contextNamesSelector);

  const navigate = useNavigate();
  const { register, handleSubmit, formState } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const handleSubmitForm = handleSubmit(async ({ data }) => {
    try {
      setLoading(true);
      const payload = JSON.parse(data);

      invariant(clusterName);
      invariant(projectName);

      const context = {
        organizationName,
        clusterName,
        projectName,
      };

      await installPlatformApp({
        ...context,
        payload,
      });

      await dispatch(getPlatformAppsInstances(context));

      navigate(PATH.APPS_INSTALLED);
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  });

  return (
    <Theme.Container>
      <form onSubmit={handleSubmitForm}>
        <Field.Textarea
          {...register('data')}
          resizable
          required
          label="JSON Payload"
          error={errors.data}
          className="min-h-[400px]"
        />
        <Button
          loading={loading}
          className="ml-auto mt-8 flex px-10"
          type="submit"
        >
          Install
        </Button>
      </form>
    </Theme.Container>
  );
};
