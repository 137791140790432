import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { PlatformApp, PlatformAppUiSchema } from '@typings';
import { PATH } from '@constants';
import { toast } from '@features';
import { installPlatformApp } from '@services/platformApps.services';
import { getPlatformAppsInstances } from '@thunks/platformApps.thunks';
import { contextNamesSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import {
  formatToCamelCase,
  invariant,
  makeAppSchema,
  removeEmptyPropertiesDeep,
  toastifyResponseError,
  unflattenObject,
} from '@utils';
import { extractSchema } from '@utils/helpers';

import { Button, Field, Modal, Theme } from '@components';
import { JobConstructorSection } from '@components/Job';
import { AppConstructorNavigationProvider } from '@components/Providers';

import { PlatformAppLogo } from './PlatformAppLogo';
import { PlatformAppsUiSchemas } from './PlatformAppsUiSchemas';

type Schema = z.infer<ReturnType<typeof makeAppSchema>>;

const makeSchema = (schemas: Record<string, any>) => makeAppSchema(schemas);

const getDefaultValues = (schema: Record<string, any>) => {
  if (!schema) {
    return {};
  }

  const values = Object.fromEntries(
    Object.entries(schema).map(
      ([key, { default: defaultValue, const: constant }]) => [
        key,
        constant || defaultValue || undefined,
      ],
    ),
  );

  return unflattenObject(values);
};

type Props = {
  template: PlatformApp.Template;
};

export const PlatformAppsInstallingConstructor: React.FC<Props> = ({
  template,
  template: { input },
}) => {
  const dispatch = useDispatch();
  const { organizationName, clusterName, projectName } =
    useSelector(contextNamesSelector);

  const navigate = useNavigate();

  const { title, logo, version, name } = formatToCamelCase(
    template,
  ) as PlatformApp.Template;

  // @ts-expect-error todo: fix type
  const { uiNestSchemas, params } = extractSchema(input);
  const flattenParams = Object.entries(
    params,
  ).reduce<PlatformAppUiSchema.ModelSchema>(
    (params, [inputName, definitions]) => {
      const flattenParams = Object.entries(definitions).map(
        ([paramName, model]) => [`${inputName}.${paramName}`, model],
      );

      return { ...params, ...Object.fromEntries(flattenParams) };
    },
    {},
  );

  const schema = makeSchema(flattenParams);
  const defaultValues = getDefaultValues(flattenParams);

  const methods = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const [loading, setLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = methods;

  const makeUiSection = (
    schemas: PlatformAppUiSchema.NestSchema[],
    index: number,
  ) => (
    <PlatformAppsUiSchemas
      key={index}
      params={flattenParams}
      schemas={schemas}
      counter={index + 1}
    />
  );

  const handleFormSubmit = handleSubmit(async ({ displayName, ...input }) => {
    try {
      setLoading(true);

      invariant(clusterName);
      invariant(projectName);

      const payload = {
        template_name: name,
        template_version: version,
        display_name: displayName,
        input: removeEmptyPropertiesDeep(input, { pattern: '' }),
      };

      await installPlatformApp({
        organizationName,
        clusterName,
        projectName,
        payload,
      });

      toast.success('App is being installed');

      await dispatch(
        getPlatformAppsInstances({
          organizationName,
          clusterName,
          projectName,
        }),
      );

      navigate(PATH.APPS_INSTALLED);
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  });

  return (
    <AppConstructorNavigationProvider>
      <FormProvider {...methods}>
        <Theme.Container className="relative mx-auto flex w-full max-w-[720px] flex-col p-0">
          <div className="flex items-center gap-4 border-b border-neural-02 px-10 py-7">
            <PlatformAppLogo src={logo} />
            <h3 className="text-h4">{title}</h3>
          </div>
          <form noValidate className="flex flex-1 flex-col gap-12 px-10 py-7">
            {uiNestSchemas.map(makeUiSection)}
            <JobConstructorSection title="Metadata">
              <Field.Input
                {...register('displayName')}
                label="App name"
                note="App display name"
                error={errors.displayName?.message}
              />
            </JobConstructorSection>
          </form>
          <Modal.Footer sticky className="mt-auto rounded-b-2xl px-10 py-8">
            <Button
              loading={loading}
              className="px-10 capitalize"
              onClick={handleFormSubmit}
            >
              Install
            </Button>
          </Modal.Footer>
        </Theme.Container>
      </FormProvider>
    </AppConstructorNavigationProvider>
  );
};
