import { useCallback } from 'react';

import { contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, path } from '@utils';

type Argument = string | null | undefined;

/**
 * For JSDoc
 */
type ReturnType = {
  /**
   * Use patterns to get context values:
   * - `%c` - cluster name;
   * - `%p` - project name;
   * - `%o` - organization name;
   */
  makeTitle: (...args: Argument[]) => string;
};

export const useHelmetTitle = (): ReturnType => {
  const { organizationName, clusterName, projectName } =
    useSelector(contextNamesSelector);

  const makeTitle = useCallback(
    (...args: Argument[]) => {
      const title = path.create(...args, { prefix: '', separator: ' - ' });

      return title
        .replace('%c', as<string>(clusterName, ''))
        .replace('%p', as<string>(projectName, ''))
        .replace('%o', as<string>(organizationName, 'No organization'));
    },
    [organizationName, clusterName, projectName],
  );

  return { makeTitle };
};
