import clsx from 'clsx';

import { PlatformAppUiSchema } from '@typings';

import { Render } from '@components/Shared';

import { PlatformAppsComponentMap } from './PlatformAppsComponentMap';

type Props = {
  params: PlatformAppUiSchema.ModelSchema;
  schemas: PlatformAppUiSchema.NestSchema[];
  counter: number;
};

export const PlatformAppsUiSchemas: React.FC<Props> = ({
  counter,
  params,
  schemas,
}) => {
  const makeComponent = (fieldName: string) => {
    return (
      <PlatformAppsComponentMap
        key={fieldName}
        name={fieldName}
        properties={params[fieldName]}
      />
    );
  };

  const makeSection = ({
    title,
    level,
    description,
    fields,
    type,
  }: PlatformAppUiSchema.NestSchema) => {
    const isTopLevel = level === 1;

    const children = schemas.filter(({ parent }) => parent === type);

    return (
      <div
        key={title}
        className={clsx({
          'rounded-xl border border-neural-02 p-6 mt-6': !isTopLevel,
        })}
      >
        <Render if={isTopLevel}>
          <div className="flex items-start gap-3">
            <p className="flex h-9 w-9 shrink-0 translate-y-[5px] items-center justify-center rounded-full bg-primary text-white">
              {counter}
            </p>
            <p className="text-h4">{title}</p>
          </div>
        </Render>
        <Render if={!isTopLevel}>
          <p className="flex items-start text-h6">{title}</p>
          <Render if={description}>
            <p className="text-footnote text-neural-04">{description}</p>
          </Render>
        </Render>
        <Render if={fields}>
          <div className="mt-4 flex flex-col gap-6">
            {fields.map(makeComponent)}
          </div>
        </Render>
        {children.map(makeSection)}
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      {schemas.filter(({ level }) => level === 1).map(makeSection)}
    </div>
  );
};
