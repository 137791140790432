import { Params, PlatformApp, WithPagination } from '@typings';
import { API_APPS } from '@constants';
import { axios } from '@features';
import { getFormattedApiUrl, path } from '@utils';

/**
 * Helper
 */
const getApiUrl = (context: Params.Context) =>
  getFormattedApiUrl({ ...context, apiUrl: API_APPS, plural: false });

export const getPlatformAppsSchemas = ({
  clusterName,
  organizationName,
  projectName,
}: Params.GetPlatformAppsSchemas) => {
  const url = getApiUrl({ clusterName, organizationName, projectName });

  return axios.get<never, PlatformApp.Schemas>(
    path.create(url, 'instances', 'schemas'),
    { formatResponse: false },
  );
};

export const getPlatformAppsTemplates = ({
  clusterName,
  organizationName,
  projectName,
}: Params.GetPlatformAppsTemplates) => {
  const url = getApiUrl({ clusterName, organizationName, projectName });

  return axios.get<never, PlatformApp.Template[]>(
    path.create(url, 'templates'),
    { formatResponse: false },
  );
};

export const getPlatformAppsInstances = ({
  clusterName,
  organizationName,
  projectName,
}: Params.GetPlatformAppsInstances) => {
  const url = getApiUrl({ clusterName, organizationName, projectName });

  return axios.get<never, WithPagination<PlatformApp.Instance>>(
    path.create(url, 'instances'),
  );
};

export const installPlatformApp = ({
  clusterName,
  organizationName,
  projectName,
  payload,
}: Params.InstallPlatformApp) => {
  const url = getApiUrl({ clusterName, organizationName, projectName });

  return axios.post<never, PlatformApp.Instance>(
    path.create(url, 'instances'),
    payload,
  );
};

export const uninstallPlatformApp = ({
  clusterName,
  organizationName,
  projectName,
  id,
}: Params.UninstallPlatformApp) => {
  const url = getApiUrl({
    clusterName,
    organizationName,
    projectName,
  });

  return axios.delete<never>(path.create(url, 'instances', id));
};

export const getPlatformAppVersions = ({
  organizationName,
  clusterName,
  projectName,
  appName,
}: Params.GetPlatformAppVersions) => {
  const url = getApiUrl({
    clusterName,
    organizationName,
    projectName,
  });

  return axios.get<never, PlatformApp.Template[]>(
    path.create(url, 'templates', appName),
  );
};
