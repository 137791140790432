import { Organization } from '@typings';

type Value = string | Organization.Name | undefined;

type Params = {
  apiUrl: string;
  clusterName?: string;
  organizationName?: Organization.Name;
  projectName?: string;
  plural?: boolean;
};

const makeUrl = (url: string, value: Value, plural: boolean): string => {
  const formattedUrl = plural ? `${url}s` : url;

  return value ? `/${formattedUrl}/${value}` : '';
};

/**
 * params = { apiUrl: 'admin-url', clusterName: 'default', organizationName: 'org-name' }
 * @example getFormattedApiUrl(params); // "/admin-url/clusters/default/orgs/org-name"
 *
 * params = { apiUrl: 'admin-url', clusterName: 'default', organizationName: null }
 * @example getFormattedApiUrl(params); // "/admin-url/clusters/default"
 */
export const getFormattedApiUrl = ({
  apiUrl,
  clusterName,
  organizationName,
  projectName,
  plural = true,
}: Params): string => {
  const cluster = makeUrl('cluster', clusterName, plural);
  const organization = makeUrl('org', organizationName, plural);
  const project = makeUrl('project', projectName, plural);

  return `${apiUrl}${cluster}${organization}${project}`;
};
