import { PlatformApp } from '@typings';
import { useContextSearchParams } from '@hooks';
import { cn, path } from '@utils';

import { Link, Render } from '@components';

import { Tags } from '../Job';

import { PlatformAppLogo } from './PlatformAppLogo';

type Props = Pick<
  PlatformApp.Template,
  'name' | 'title' | 'logo' | 'tags' | 'shortDescription'
>;

export const PlatformAppTemplate = ({
  name,
  title,
  logo,
  shortDescription,
  tags,
}: Props) => {
  const { contextSearchParams } = useContextSearchParams();

  const exploreLink = `${path.exploreApp(name)}?${contextSearchParams}`;

  return (
    <div className="relative flex">
      <div className="peer relative z-20 flex min-h-[160px] max-w-full flex-1 flex-col gap-4 overflow-hidden rounded-xl border border-transparent bg-white p-6 transition-colors hover:border-neural-02">
        <div className="relative flex min-w-0 gap-3">
          <Link theme to={exploreLink} className="contents">
            <PlatformAppLogo src={logo} size="md" />
          </Link>
          <div
            className={cn('flex flex-col justify-center gap-1', {
              'justify-between': !!shortDescription,
            })}
          >
            <Link
              theme
              to={exploreLink}
              className="text-h5 leading-[32px] text-black"
            >
              {title}
            </Link>
            <Render if={shortDescription}>
              <p className="-translate-y-1 text-body-medium text-neural-04">
                {shortDescription}
              </p>
            </Render>
          </div>
        </div>
        <Render if={tags}>
          <Tags restrictionAmount={3} tags={tags} />
        </Render>
        <div className="mt-auto flex justify-end gap-3">
          <Link className="capitalize" variant="secondary" to={exploreLink}>
            Explore
          </Link>
          <Link
            className="capitalize"
            to={`${path.installApp(name)}?${contextSearchParams}`}
          >
            Install
          </Link>
        </div>
      </div>
      <Render if={false}>
        <div className="absolute -bottom-1 -right-1 z-10 h-full w-full rounded-xl bg-neural-03/30" />
        <div className="absolute -bottom-2 -right-2 z-10 h-full w-full rounded-xl bg-neural-03/20" />
      </Render>
    </div>
  );
};
