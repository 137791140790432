import { AnyOf, PlatformApp, SchemaDefinition, SchemaProperty } from '@typings';
import { as } from '@utils';

type InputsMetadata = Record<string, PlatformApp.InputMetadata>;

export const getInputMetadata = (
  input: PlatformApp.InputType,
): InputsMetadata => {
  if (!input.properties) {
    return {};
  }

  return Object.entries(input.properties).reduce<InputsMetadata>(
    // @ts-expect-error todo: fix type
    (metadata, [propertyName, { $ref, anyOf = [] }]) => {
      const ref = $ref || (anyOf as AnyOf).find((s) => s.$ref)?.$ref;

      if (!ref) {
        return metadata;
      }

      const protocolName: SchemaDefinition.DefinitionName = ref.replace(
        /^#\/\$defs\//,
        '',
      );

      /**
       * Waiting for fixing external properties `x-{propertyName}`
       */
      const {
        title,
        description,
        logoUrl,
        'x-title': externalTitle,
        xTitle = externalTitle,
        'x-description': externalDescription,
        xDescription = externalDescription,
        'x-logo-url': externalLogoUrl,
        xLogoUrl = externalLogoUrl,
      } =
        // @ts-expect-error todo: fix type
        as.o<SchemaProperty.Property>(input.$defs[protocolName]);

      return {
        ...metadata,
        [propertyName]: {
          title: (xTitle ?? title) as string,
          description: xDescription ?? description,
          logoUrl: xLogoUrl ?? logoUrl,
        },
      };
    },
    {},
  );
};
