import { faMessageXmark } from '@fortawesome/pro-thin-svg-icons';

import { PlatformApp } from '@typings';
import { PATH } from '@constants';
import { uninstallPlatformApp } from '@services';
import { getPlatformAppsInstances } from '@thunks/platformApps.thunks';
import {
  contextNamesSelector,
  platformAppsInstancesSelector,
  platformAppsLoadersSelector,
} from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { invariant, toastifyResponseError } from '@utils';

import { ChaseSpinner, Link } from '@components';
import { EmptyContent } from '@components/Ui';
import { PlatformAppInstalledItem } from '@components/Ui/PlatformApps';

export const PlatformAppsInstalledPage: React.FC = () => {
  const dispatch = useDispatch();
  const { organizationName, clusterName, projectName } =
    useSelector(contextNamesSelector);
  const { instancesFetched, instancesLoading } = useSelector(
    platformAppsLoadersSelector,
  );
  const instances = useSelector(platformAppsInstancesSelector);

  const handleAppUninstall = async (id: PlatformApp.Instance['id']) => {
    try {
      invariant(projectName);
      invariant(clusterName);

      const context = { organizationName, clusterName, projectName };

      await uninstallPlatformApp({ ...context, id });
      await dispatch(getPlatformAppsInstances(context));
    } catch (error) {
      toastifyResponseError(error);
    }
  };

  const makeInstance = ({
    id,
    name,
    displayName,
    state,
    templateName,
    templateVersion,
  }: PlatformApp.Instance) => {
    return (
      <PlatformAppInstalledItem
        key={id}
        name={name}
        displayName={displayName}
        state={state}
        templateName={templateName}
        templateVersion={templateVersion}
        onUninstall={() => handleAppUninstall(id)}
      />
    );
  };

  if (instancesLoading || !instancesFetched) {
    return (
      <div className="flex justify-center">
        <ChaseSpinner color="primary" className="absolute mt-40 h-14 w-14" />
      </div>
    );
  }

  if (!instances.length) {
    return (
      <EmptyContent
        icon={faMessageXmark}
        title="No Apps Installed"
        text="It looks like you haven’t installed any apps yet. Visit the Apps page to explore and install apps to get started"
        className="mt-40"
      >
        <Link to={PATH.APPS} className="capitalize">
          Explore apps
        </Link>
      </EmptyContent>
    );
  }

  return (
    <div className="grid grid-cols-3 gap-4 hdp:grid-cols-2">
      {instances.map(makeInstance)}
    </div>
  );
};
