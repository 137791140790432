import { useEffect } from 'react';

import { getPlatformAppsSchemas, getPlatformAppsTemplates } from '@services';
import {
  resetPlatformAppsInstances,
  resetPlatformAppsTemplates,
  setPlatformAppsFetcher,
  setPlatformAppsLoader,
  setPlatformAppsSchemas,
  setPlatformAppsTemplates,
} from '@slices';
import { getPlatformAppsInstances } from '@thunks/platformApps.thunks';
import { contextNamesSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { invariant, toastifyResponseError } from '@utils';

export const usePlatformApps = () => {
  const dispatch = useDispatch();
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  useEffect(() => {
    const getSchemas = async () => {
      try {
        invariant(clusterName);
        invariant(projectName);

        dispatch(setPlatformAppsLoader({ schema: true }));

        const schemas = await getPlatformAppsSchemas({
          organizationName,
          clusterName,
          projectName,
        });

        dispatch(setPlatformAppsSchemas(schemas));
        dispatch(setPlatformAppsFetcher({ schema: true }));
      } catch (error) {
        toastifyResponseError(error);
      } finally {
        dispatch(setPlatformAppsLoader({ schema: false }));
      }
    };

    if (organizationName && clusterName && projectName) {
      getSchemas();
    }
  }, [organizationName, clusterName, projectName, dispatch]);

  useEffect(() => {
    const getTemplates = async () => {
      try {
        invariant(clusterName);
        invariant(projectName);

        dispatch(setPlatformAppsLoader({ templates: true }));

        const templates = await getPlatformAppsTemplates({
          organizationName,
          clusterName,
          projectName,
        });

        dispatch(setPlatformAppsTemplates(templates));
        dispatch(setPlatformAppsFetcher({ templates: true }));
      } catch (error) {
        toastifyResponseError(error);
      } finally {
        dispatch(setPlatformAppsLoader({ templates: false }));
      }
    };

    if (organizationName && clusterName && projectName) {
      getTemplates();
    } else {
      dispatch(resetPlatformAppsTemplates());
    }
  }, [organizationName, clusterName, projectName, dispatch]);

  useEffect(() => {
    const getInstances = async () => {
      try {
        invariant(clusterName);
        invariant(projectName);

        dispatch(setPlatformAppsLoader({ instances: true }));

        dispatch(
          getPlatformAppsInstances({
            organizationName,
            clusterName,
            projectName,
          }),
        );

        dispatch(setPlatformAppsFetcher({ instances: true }));
      } catch (error) {
        toastifyResponseError(error);
      } finally {
        dispatch(setPlatformAppsLoader({ instances: false }));
      }
    };

    if (organizationName && clusterName && projectName) {
      getInstances();
    } else {
      dispatch(resetPlatformAppsInstances());
    }
  }, [organizationName, clusterName, projectName, dispatch]);
};
