import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';

import { Field } from '@components';

type Props = {
  name: string;
  required?: boolean;
  label?: string;
  note?: string;
  error?: string;
};
export const AppClusterNameField: React.FC<Props> = ({
  name,
  required,
  label,
  note,
  error,
}) => {
  const { clusterName } = useSelector(contextNamesSelector);

  const { register, setValue } = useFormContext();

  useEffect(() => {
    if (name && clusterName) {
      setValue(name, clusterName);
    }
  }, [name, clusterName, setValue]);

  return (
    <Field.Input
      {...register(name)}
      disabled
      label={label}
      note={note}
      required={required}
      error={error}
    />
  );
};
