import { PlatformApp } from '@typings';
import {
  platformAppsLoadersSelector,
  platformAppsTemplatesSelector,
} from '@selectors';
import { useSelector } from '@hooks';
import { formatToCamelCase } from '@utils';

import { ChaseSpinner } from '@components';
import { PlatformAppTemplate } from '@components/Ui/PlatformApps';

export const PlatformAppsTemplatesPage: React.FC = () => {
  const { templatesLoading, templatesFetched } = useSelector(
    platformAppsLoadersSelector,
  );
  const templates = useSelector(platformAppsTemplatesSelector);

  const formattedTemplates = formatToCamelCase(
    templates,
  ) as PlatformApp.Template[];

  const makeTemplate = ({
    name,
    title,
    tags,
    shortDescription,
    logo,
  }: PlatformApp.Template) => (
    <PlatformAppTemplate
      key={name}
      name={name}
      title={title}
      tags={tags}
      shortDescription={shortDescription}
      logo={logo}
    />
  );

  if (templatesLoading || !templatesFetched) {
    return (
      <div className="flex justify-center">
        <ChaseSpinner color="black" className="absolute mt-40 h-14 w-14" />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-3 gap-4 hdp:grid-cols-2">
      {formattedTemplates.map(makeTemplate)}
    </div>
  );
};
