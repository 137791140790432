import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { PlatformAppUiSchema } from '@typings';
import { normalizePlatformAppsFormErrors } from '@utils';

import {
  AppClusterNameField,
  AppConstructorArguments,
  AppHuggingFaceTokenField,
  JobConstructorEnvironments,
  JobPresetField,
} from '@components/Job';
import { Field } from '@components/Shared';

const HIDDEN_FIELDS = ['valueFrom.secretKeyRef.name'];

type Props = {
  required?: boolean;
  name: string;
  properties: PlatformAppUiSchema.Model;
};

export const PlatformAppsComponentMap: React.FC<Props> = ({
  name,
  properties: { title, description, required, type },
}) => {
  const { register, formState } = useFormContext();

  const isHiddenField = HIDDEN_FIELDS.some((fieldName) =>
    new RegExp(fieldName).test(name),
  );
  const { [name]: error } = normalizePlatformAppsFormErrors(formState.errors);

  const componentByType = (() => {
    switch (type) {
      case 'boolean':
        return (
          <Field.Checkbox
            {...register(name)}
            required={required}
            note={description}
            error={error}
            label={title}
          >
            {title}
          </Field.Checkbox>
        );
      case 'string':
        return (
          <Field.Input
            {...register(name)}
            required={required}
            label={title}
            note={description}
            error={error}
          />
        );
      case 'integer':
      case 'number':
      case 'float':
        return (
          <Field.Input
            {...register(name)}
            type="number"
            inputMode="numeric"
            required={required}
            label={title}
            note={description}
            error={error}
          />
        );
      default:
        return null;
    }
  })();

  const customComponent = (() => {
    const validate = (regExp: string) => new RegExp(regExp).test(name);

    switch (true) {
      case validate('preset.name'):
        return (
          <JobPresetField
            required={required}
            name={name}
            note={description}
            error={error}
          />
        );
      case validate('clusterName'):
        return (
          <AppClusterNameField
            required={required}
            name={name}
            label={title}
            note={description}
            error={error}
          />
        );
      case validate('server_extra_args'):
      case validate('args'):
      case validate('command'):
        return (
          <AppConstructorArguments
            name={name}
            label={title}
            note={description}
          />
        );
      case validate('env'):
        return (
          <JobConstructorEnvironments
            name={name}
            label={title}
            note={description}
          />
        );
      case validate('valueFrom.secretKeyRef.key'):
        return (
          <AppHuggingFaceTokenField
            required={required}
            name={name}
            label={title}
            note={description}
          />
        );
      default:
        return null;
    }
  })();

  const component = customComponent ?? componentByType;
  const isComponentSupported = !!component;

  useEffect(() => {
    if (isHiddenField && name) {
      register(name);
    }
  }, [name, isHiddenField, register]);

  useEffect(() => {
    if (!isComponentSupported && !isHiddenField) {
      // eslint-disable-next-line no-console
      console.log(`Component ${name} is not supported`);
    }
  }, [name, isHiddenField, isComponentSupported]);

  if (isHiddenField) {
    return null;
  }

  return component;
};
