/**
 * Normalizes URL path for template version
 *
 * "feature-version" > "feature/version"
 */
export const normalizeVersion = (version: string) =>
  version.replaceAll('-', '/');

/**
 * Process template version for link's `href`
 *
 * "feature/version" > "feature-version"
 */
export const processVersion = (version: string) => version.replaceAll('/', '-');
