import { Navigate, Route, Routes } from 'react-router-dom';

import { PATH } from '@constants';

import * as Page from '@pages';
import { PlatformAppsLayout } from '@components/Layouts';

export const PlatformAppsRoutes = () => {
  return (
    <Routes>
      <Route element={<PlatformAppsLayout />}>
        <Route index element={<Page.PlatformAppsTemplates />} />
        <Route path="installed" element={<Page.PlatformAppsInstalled />} />
        <Route path="legacy" element={<Page.LegacyApps />} />
        <Route path="dev-install" element={<Page.PlatformAppsDevInstall />} />
      </Route>
      <Route
        path=":appName/:version"
        element={<Page.PlatformAppsExploration />}
      />
      <Route path=":appName" element={<Page.PlatformAppsExploration />} />
      <Route
        path=":appName/install"
        element={<Page.PlatformAppsInstalling />}
      />
      <Route path="*" element={<Navigate replace to={PATH.APPS} />} />
    </Routes>
  );
};
