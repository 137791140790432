import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { faMessageXmark } from '@fortawesome/pro-thin-svg-icons';

import { PlatformApp } from '@typings';
import { API_APPS, PATH } from '@constants';
import { axios } from '@features';
import {
  platformAppsAppInstancesSelector,
  platformAppsLoadersSelector,
} from '@selectors';
import { useSelector } from '@hooks';
import { usePlatformAppsTemplates } from '@hooks/platformApps';
import { as, formatToCamelCase, path } from '@utils';
import { getInputMetadata } from '@utils/helpers';

import { Button, ChaseSpinner, Helmet, Link, Render, Theme } from '@components';
import { Layout } from '@components/Layouts';
import { EmptyContent } from '@components/Ui';
import { Tags } from '@components/Ui/Job';
import {
  PlatformAppAssets,
  PlatformAppItem,
  PlatformAppItemLink,
  PlatformAppLogo,
  PlatformAppTemplateVersion,
} from '@components/Ui/PlatformApps';

export const PlatformAppsExplorationPage: React.FC = () => {
  const { appName } = useParams();

  const instances = useSelector(platformAppsAppInstancesSelector(appName!));

  const { templatesLoading } = useSelector(platformAppsLoadersSelector);

  const data = usePlatformAppsTemplates();

  const { template, latestVersion, versions } = data;

  const {
    title,
    logo,
    description,
    shortDescription,
    version,
    pubDate,
    tags,
    documentationUrls,
    externalUrls,
    input,
    output,
    assets,
  } = as.o<PlatformApp.Template>(formatToCamelCase(template));

  const installedAppCount = instances.length;
  const formattedTitle = `${title || appName} App`;

  useEffect(() => {
    axios.get(
      `${API_APPS}/cluster/default/org/dev/project/default/templates/${appName}`,
    );
  }, [appName]);

  const makeDocumentationLink = ({
    text,
    url,
  }: PlatformApp.Template['documentationUrls'][number]) => (
    <PlatformAppItemLink external key={url} to={url} title={text} />
  );

  const makeReferenceLink = ({
    text,
    url,
  }: PlatformApp.Template['externalUrls'][number]) => (
    <PlatformAppItemLink icon key={url} to={url} title={text} />
  );

  /**
   * Removes duplicated types by `title`
   */
  const normalizeTypes = (types: Record<string, PlatformApp.InputMetadata>) => {
    // console.log(types);

    return Array.from(
      new Map(Object.values(types).map((type) => [type.title, type])).values(),
    );
  };

  const makeType = ({
    title,
    description,
    logoUrl,
  }: PlatformApp.InputMetadata) => {
    return (
      <PlatformAppItem
        key={title}
        title={title!}
        image={logoUrl}
        description={description}
      />
    );
  };

  const renderContent = () => {
    if (templatesLoading) {
      return (
        <div className="mt-20 flex justify-center">
          <ChaseSpinner color="black" className="absolute mt-40 h-14 w-14" />
        </div>
      );
    }

    if (!template) {
      return (
        <EmptyContent
          variant="layout"
          icon={faMessageXmark}
          title="App is unavailable"
          text="The app is currently temporarily unavailable. Please return to the apps to try again"
        >
          <Link to={PATH.APPS} className="capitalize">
            Explore apps
          </Link>
        </EmptyContent>
      );
    }

    return (
      <Theme.Container className="mx-auto flex max-w-[1328px] items-start gap-8 px-8 py-12">
        <PlatformAppLogo src={logo} size="lg" />
        <div className="flex-1">
          <h2 className="text-h4">{title}</h2>
          <p className="mt-1 text-neural-04">{shortDescription}</p>
          <PlatformAppTemplateVersion
            pubDate={pubDate}
            version={version}
            latestVersion={latestVersion}
            versions={versions}
          />
          <div className="mb-16 mt-6 flex gap-4">
            <Link to={path.installApp(appName)} className="px-10">
              Install
            </Link>
            <Link theme to={PATH.APPS_INSTALLED} disabled={!installedAppCount}>
              <Button
                stopPropagation={false}
                disabled={!installedAppCount}
                variant="primary-outline"
                className="capitalize"
              >
                View installed{' '}
                <Render if={installedAppCount > 0}>
                  ({installedAppCount})
                </Render>
              </Button>
            </Link>
          </div>
          <Render if={assets}>
            <PlatformAppAssets assets={assets} />
          </Render>
          <p className="mt-6 text-neural-04">{description}</p>
          <div className="mt-6">
            <Tags restrictionAmount={null} tags={tags} />
          </div>
          <div className="mt-16 flex gap-6">
            <div className="flex flex-1 flex-col gap-5">
              <p className="text-h5">Related</p>
              <PlatformAppItem
                title="Related App Name"
                description="Main function of the application"
              />
            </div>
            <div className="flex flex-1 flex-col gap-14">
              <Render if={documentationUrls}>
                <div className="flex flex-col gap-3">
                  <p className="mb-2 text-h5">Documentation</p>
                  {documentationUrls.map(makeDocumentationLink)}
                </div>
              </Render>
              <Render if={externalUrls}>
                <div className="flex flex-col gap-5">
                  <p className="text-h5">References</p>
                  {externalUrls.map(makeReferenceLink)}
                </div>
              </Render>
            </div>
          </div>
          <div className="mt-16 flex gap-6">
            <div className="flex flex-1 flex-col gap-5 rounded-xl bg-neural-01 px-10 py-6">
              <p className="mb-1 text-h5 capitalize">Input types</p>
              {normalizeTypes(getInputMetadata(input)).map(makeType)}
            </div>
            <div className="flex flex-1 flex-col gap-5 rounded-xl bg-neural-01 px-10 py-6">
              <p className="mb-1 text-h5 capitalize">Output types</p>
              {normalizeTypes(getInputMetadata(output)).map(makeType)}
            </div>
          </div>
        </div>
      </Theme.Container>
    );
  };

  return (
    <Layout backTo={PATH.APPS} title={formattedTitle}>
      <Helmet title={formattedTitle} />
      <Layout.Content>{renderContent()}</Layout.Content>
    </Layout>
  );
};
