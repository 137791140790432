import { AppState, Params, Thunk } from '@typings';
import * as services from '@services';
import { setPlatformAppsInstances } from '@slices/cache/platformApps/platformAppsInstances.slice';

export const getPlatformAppsInstances =
  (
    context: Params.GetPlatformAppsInstances,
  ): Thunk<Promise<AppState.PlatformApps['instances']>> =>
  async (dispatch) => {
    try {
      const { items: instances } =
        await services.getPlatformAppsInstances(context);

      dispatch(setPlatformAppsInstances(instances));

      return instances;
    } catch (error) {
      return Promise.reject(error);
    }
  };
