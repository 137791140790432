import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

import { ClusterSettings } from '@typings';
import { IS_STAGING, PATH } from '@constants';
import { ensureArray, tv } from '@utils';

import { Icon, Link, Render } from '@components';

const variants = tv({
  base: 'px-6 py-2 flex items-center justify-center rounded-full capitalize gap-1.5 transition-[padding]',
  variants: {
    isActive: {
      true: 'text-white bg-rebecca pl-4',
      false: 'text-rebecca bg-white',
    },
  },
  defaultVariants: {
    isActive: false,
  },
});

const TABS = [
  {
    title: 'All apps',
    path: PATH.APPS,
  },
  {
    title: 'Installed apps',
    path: PATH.APPS_INSTALLED,
  },
  {
    title: 'Legacy apps',
    path: PATH.APPS_LEGACY,
  },
];

if (!IS_STAGING) {
  TABS.push({ title: 'Dev install', path: PATH.APPS_DEV_INSTALL });
}

type Props = {
  setTitle: (partial: string) => void;
};

export const AppsTabs = ({ setTitle }: Props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const tab = TABS.find(({ path }) => ensureArray(path).includes(pathname));

    if (tab) {
      setTitle(tab.title);
    }
  }, [pathname, setTitle]);

  const makeTab = ({ path, title }: ClusterSettings.Tab) => {
    const paths = ensureArray(path);
    const isActive = paths.includes(pathname);
    const [to] = paths;

    return (
      <Link theme key={to} to={to} className={variants({ isActive })}>
        <Render if={isActive}>
          <Icon icon={faCheck} className="text-[20px]" />
        </Render>
        {title}
      </Link>
    );
  };

  return <div className="flex gap-4">{TABS.map(makeTab)}</div>;
};
