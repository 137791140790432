import { useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';

import { cn, path, processVersion } from '@utils';

import { Button, Icon, Link, Popover, Render } from '@components/Shared';

type Props = {
  pubDate: string;
  latestVersion: string;
  version: string;
  versions: string[];
};

export const PlatformAppTemplateVersion: React.FC<Props> = ({
  pubDate,
  latestVersion,
  version: templateVersion,
  versions,
}) => {
  const { appName } = useParams();

  const [open, setOpen] = useState(false);

  const isLatest = latestVersion === templateVersion;

  const versioning = versions.length > 1;

  const makeVersion = (version: string) => (
    <Button
      theme
      key={version}
      className="text-left"
      onClick={() => setOpen(false)}
    >
      <Link
        theme
        replace
        to={path.exploreApp(appName, processVersion(version))}
        className={cn({ 'text-primary': version === templateVersion })}
      >
        {version}
      </Link>
    </Button>
  );

  return (
    <Popover
      open={open}
      side="bottom"
      align="end"
      disabled={!versioning}
      onOpenChange={setOpen}
    >
      <div slot="trigger" className="relative mt-6 flex items-center gap-1.5">
        <p className="text-body-medium">
          Version: {templateVersion}{' '}
          <span className="text-neural-04">
            ({dayjs(pubDate).local().format('LL')})
          </span>
        </p>
        <Render if={versioning}>
          <Icon
            icon={faChevronDown}
            className="h-4 w-4 -translate-y-px text-[12px]"
          />
        </Render>
        <Render if={isLatest}>
          <div className="absolute left-full top-1/2 flex h-6 w-[122px] -translate-y-1/2 translate-x-1.5 items-center justify-center gap-1 rounded-xl bg-neural-01 py-1 pl-2 pr-3 text-body-small text-black">
            <Icon icon={faSparkles} className="h-4 w-4 text-neural-04" />
            Latest version
          </div>
        </Render>
      </div>
      <div className="flex flex-col">{versions.map(makeVersion)}</div>
    </Popover>
  );
};
