export const ONBOARDING = '/user-onboarding';
export const CREATE_ORGANIZATION = '/create-org';
export const CREATE_PROJECT = '/new-project';

export const AUTH = '/auth';
export const INVITE_SIGNUP = '/invite-signup';

export const DASHBOARD = '/dashboard';
export const STORAGE = '/files';
export const BUCKET = '/bucket';
export const BUCKETS = '/buckets';
export const DISKS = '/disks';
export const IMAGES = '/images';
export const SECRETS = '/secrets';
export const JOB = '/job-details';
export const JOBS = '/jobs';
export const NEW_JOB = '/new-job';
export const BAKE = '/bake';
export const FLOW = '/flow';
export const FLOWS = '/flows';
export const ORGANIZATIONS_INVITES = '/invites';

export const CLUSTER_SETTINGS = '/cluster-settings';
export const CLUSTER_SETTINGS_GENERAL = `${CLUSTER_SETTINGS}/general`;
export const CLUSTER_SETTINGS_USERS = `${CLUSTER_SETTINGS}/users`;
export const CLUSTER_SETTINGS_NODES = `${CLUSTER_SETTINGS}/nodes`;
export const CLUSTER_SETTINGS_RESOURCES = `${CLUSTER_SETTINGS}/resources`;
export const CLUSTER_SETTINGS_REPORTS = `${CLUSTER_SETTINGS}/reports`;

export const ORGANIZATION_SETTINGS = '/org-settings';
export const ORGANIZATION_SETTINGS_GENERAL = `${ORGANIZATION_SETTINGS}/general`;
export const ORGANIZATION_SETTINGS_USERS = `${ORGANIZATION_SETTINGS}/users`;
export const ORGANIZATION_SETTINGS_REPORTS = `${ORGANIZATION_SETTINGS}/reports`;

export const APP = '/app-details';
export const APPS = '/apps';
export const APPS_INSTALLED = `${APPS}/installed`;
export const APPS_LEGACY = `${APPS}/legacy`;
export const APPS_DEV_INSTALL = `${APPS}/dev-install`;

export const PROJECT_SETTINGS = '/project-settings';
export const PROJECT_SETTINGS_GENERAL = `${PROJECT_SETTINGS}/general`;
export const PROJECT_SETTINGS_USERS = `${PROJECT_SETTINGS}/users`;
export const PROJECT_SETTINGS_REPORTS = `${PROJECT_SETTINGS}/reports`;

export const BILLING = '/org-billing';
export const BILLING_USAGE = `${BILLING}/usage`;
export const BILLING_CREDITS = `${BILLING}/credits`;

export const HOME = APPS;

export const DOCS = 'https://docs.apolo.us';
export const TERMS_OF_USE = 'https://apolo.us/terms-of-use';
