import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Render } from '@components/Shared';

import { PlatformAppLogo } from './PlatformAppLogo';

type Props = {
  title: string;
  description?: string;
  icon?: IconProp;
  image?: string;
};

export const PlatformAppItem: React.FC<Props> = ({
  title,
  description,
  image,
}) => {
  return (
    <div className="flex items-center gap-4">
      <PlatformAppLogo src={image} />
      <div>
        <p className="text-headline">{title}</p>
        <Render if={description}>
          <p className="mt-1 text-neural-04">{description}</p>
        </Render>
      </div>
    </div>
  );
};
