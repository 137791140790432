import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getPlatformAppVersions } from '@services/platformApps.services';
import {
  platformAppsVersionsRequest,
  setPlatformAppsVersions,
} from '@slices/cache/platformApps';
import {
  contextNamesSelector,
  platformAppsTemplateSelector,
  platformAppsVersionsSelector,
} from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { invariant, toastifyResponseError } from '@utils';
import { normalizeVersion } from '@utils/helpers';

type Params = {
  version: string | undefined;
  appName: string;
};

export const usePlatformAppsTemplates = () => {
  const { version: versionParam, appName } = useParams() as Params;

  const dispatch = useDispatch();
  const { organizationName, clusterName, projectName } =
    useSelector(contextNamesSelector);
  const template = useSelector(platformAppsTemplateSelector(appName));
  const {
    fetched,
    templates,
    appName: templateName,
  } = useSelector(platformAppsVersionsSelector);

  const isVersion = !!versionParam;
  const isVersionLatest = versionParam === 'latest' || !isVersion;

  const templateVersion = template?.version ?? '';

  const latestVersion = templateVersion;

  const versions = templates.map(({ version }) => version);

  const formattedVersion =
    isVersion && !isVersionLatest
      ? normalizeVersion(versionParam)
      : latestVersion;

  const versionTemplate = isVersion
    ? templates.find(({ version }) => version === formattedVersion)
    : template;

  useEffect(() => {
    const getVersions = async () => {
      try {
        dispatch(platformAppsVersionsRequest());

        invariant(clusterName);
        invariant(projectName);

        const templates = await getPlatformAppVersions({
          organizationName,
          clusterName,
          projectName,
          appName,
        });

        dispatch(setPlatformAppsVersions({ appName, templates }));
      } catch (error) {
        toastifyResponseError(error);
      }
    };

    if (templateName !== appName) {
      getVersions();
    }
  }, [
    organizationName,
    clusterName,
    projectName,
    appName,
    templateName,
    fetched,
    dispatch,
  ]);

  return {
    template: versionTemplate,
    versions,
    latestVersion,
    version: formattedVersion,
    isVersion,
    templates,
  };
};
