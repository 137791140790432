import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState, PlatformAppLoader } from '@typings';

type State = AppState.PlatformApps['loaders'];

const initialState: State = {
  templates: false,
  schema: false,
  instances: false,
};

/**
 * Helper to track if specific platform apps state was fetched
 */
export const platformAppsFetchersSlice = createSlice({
  name: 'platform-apps-fetcher',
  initialState,
  reducers: {
    setPlatformAppsFetcher: (state, action: PayloadAction<Partial<State>>) => {
      Object.entries(action.payload).forEach(([loader, value]) => {
        state[loader as PlatformAppLoader] = value;
      });
    },
  },
});

export const { setPlatformAppsFetcher } = platformAppsFetchersSlice.actions;

export default platformAppsFetchersSlice.reducer;
