import { createSelector } from 'reselect';

import { PlatformApp, PlatformAppLoader } from '@typings';
import { as } from '@utils';

import { cacheSelector } from './cache.selectors';

export const platformAppsSelector = createSelector(
  cacheSelector,
  (cache) => cache.platformApps,
);

export const platformAppsLoadersSelector = createSelector(
  platformAppsSelector,
  (platformApps) => {
    type AppendSuffix<T extends string, S extends string> = `${T}${S}`;

    type WithSuffix<T, Suffix extends string> = {
      [K in keyof T as AppendSuffix<K & string, Suffix>]: T[K];
    };

    const handler = <
      Loader extends Record<PlatformAppLoader, boolean>,
      Suffix extends string,
    >(
      loaders: Loader,
      suffix: Suffix,
    ): WithSuffix<Loader, Suffix> =>
      Object.fromEntries(
        Object.entries(loaders).map(([loader, value]) => [
          `${loader}${suffix}`,
          value,
        ]),
      ) as WithSuffix<Loader, Suffix>;

    const { loaders, fetchers } = platformApps;

    return {
      ...handler(loaders, 'Loading'),
      ...handler(fetchers, 'Fetched'),
    };
  },
);

export const platformAppsTemplatesSelector = createSelector(
  platformAppsSelector,
  (platformApps) => platformApps.templates,
);

export const platformAppsSchemasSelector = createSelector(
  platformAppsSelector,
  (platformApps) => platformApps.schemas,
);

export const platformAppsInstancesSelector = createSelector(
  platformAppsSelector,
  (platformApps) => platformApps.instances,
);

export const platformAppsAppInstancesSelector = (appName: string) =>
  createSelector(platformAppsInstancesSelector, (apps) =>
    apps.filter(({ templateName }) => templateName === appName),
  );

export const platformAppsTemplateSelector = (appName: string) =>
  createSelector(platformAppsTemplatesSelector, (templates) => {
    return templates.find(({ name }) => name === appName);
  });

export const platformAppsTemplateSchemasSelector = (appName: string) =>
  createSelector(
    platformAppsSchemasSelector,
    platformAppsTemplateSelector(appName),
    (schemas, template) => {
      const appSchemas = Object.values<PlatformApp.InputType>(
        as.o(template?.input),
      ).reduce<PlatformApp.Schemas>((schema, { title: inputName }: any) => {
        if (schemas[inputName]) {
          schema[inputName] = schemas[inputName];
        }

        return schema;
      }, {});

      return { template, schemas: appSchemas };
    },
  );

export const platformAppsCacheSelector = createSelector(
  platformAppsSelector,
  ({ cache }) => cache,
);

export const platformAppsVersionsSelector = createSelector(
  platformAppsCacheSelector,
  ({ versions }) => versions,
);
