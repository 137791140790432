export const PACKAGE_VERSION = process.env.REACT_APP_PACKAGE_VERSION ?? '';
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN ?? '';
export const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? '';

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const IS_STAGING = ENVIRONMENT === 'staging';
export const IS_DEV = ENVIRONMENT === 'dev';
export const IS_LOCAL = !IS_STAGING && !IS_DEV;

export const ORIGIN = process.env.REACT_APP_ORIGIN ?? '';

export const PLATFORM_API_URL = process.env.REACT_APP_PLATFORM_API_URL ?? '';
export const API_ROOT = '/api/v1';
export const API_ADMIN = '/apis/admin/v1';
export const API_BILLING = '/apis/billing/v1';
export const API_APPS = '/apis/apps/v1';

export const AUTH_SERVICE = process.env.REACT_APP_AUTH_SERVICE ?? '';

export const SUPPORT_EMAIL = 'support@apolocloudinc.zendesk.com';

export const AUTH0 = {
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE ?? '',
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
};
