import { useContext, useEffect, useRef, useState } from 'react';

import { cn, formatModelName } from '@utils';

import { Button } from '@components';
import { AppConstructorNavigationContext } from '@components/Providers';

export const JobConstructorNavigator = () => {
  const { navigationTitles } = useContext(AppConstructorNavigationContext);

  const sectionsRef = useRef<NodeListOf<Element>>();
  const [activeName, setActiveName] = useState<string>(navigationTitles[0]);

  useEffect(() => {
    if (navigationTitles.length) {
      setActiveName(navigationTitles[0]);
    }
  }, [navigationTitles]);

  useEffect(() => {
    if (!navigationTitles.length) {
      return;
    }

    const anchorsSelector = navigationTitles
      .map((title) => `#${formatModelName(title)}`)
      .join(',');
    const sections = document.querySelectorAll(anchorsSelector);

    if (sections) {
      sectionsRef.current = sections;
    }
  }, [navigationTitles]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = sectionsRef.current;

      if (!sections) {
        return;
      }

      const element = Array.from(sections).find((element) => {
        const { top, bottom } = element.getBoundingClientRect();

        return bottom > 0 && top < window.innerHeight;
      });

      if (element) {
        const name = element.getAttribute('id') as string;

        setActiveName(name);
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavigatorClick = (name: string) => {
    const SCROLL_TO_TIMEOUT = 450;
    const HEADER_OFFSET = -200;
    const top =
      document.getElementById(name)!.getBoundingClientRect().top +
      window.pageYOffset +
      HEADER_OFFSET;

    window.scrollTo({ top, behavior: 'smooth' });

    setTimeout(() => {
      setActiveName(name);
    }, SCROLL_TO_TIMEOUT);
  };

  const makeNavigationItem = (title: string, index: number) => {
    const name = formatModelName(title);
    const isActive = name === activeName;

    return (
      <Button
        theme
        key={name}
        className={cn(
          'group flex gap-3 text-neural-04 transition-colors hover:text-black',
          { '!text-black': isActive },
        )}
        onClick={() => handleNavigatorClick(name)}
      >
        <span
          className={cn(
            'flex h-9 w-9 shrink-0 translate-y-[-5px] items-center justify-center rounded-full bg-neural-02 font-600 transition-colors',
            { '!bg-primary !text-white': isActive },
          )}
        >
          {index + 1}
        </span>
        <span className="text-left text-h6">{title}</span>
      </Button>
    );
  };

  return (
    <div className="relative">
      <div className="sticky left-10 top-36 flex max-w-[240px] flex-col gap-10">
        {navigationTitles.map(makeNavigationItem)}
      </div>
    </div>
  );
};
