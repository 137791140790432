import { useState } from 'react';

import { AnyFunction, PlatformApp } from '@typings';
import { platformAppsTemplateSelector } from '@selectors';
import { useSelector } from '@hooks';
import { noop, toastifyResponseError } from '@utils';

import { Button } from '@components/Shared';

import { PlatformAppLogo } from './PlatformAppLogo';

type Props = Pick<
  PlatformApp.Instance,
  'name' | 'displayName' | 'state' | 'templateName' | 'templateVersion'
> & {
  className?: string;
  onUninstall?: AnyFunction;
};

export const PlatformAppInstalledItem: React.FC<Props> = ({
  name,
  displayName,
  state,
  templateName,
  onUninstall = noop,
}) => {
  const template = useSelector(platformAppsTemplateSelector(templateName));

  const [loading, setLoading] = useState(false);

  const handleUninstall = async () => {
    try {
      setLoading(true);

      await onUninstall();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative">
      <div className="group peer relative z-20 flex min-h-[160px] gap-6 rounded-xl border border-transparent bg-white p-6 transition-colors hover:border-neural-02">
        <PlatformAppLogo src={template?.logo} size="md" />
        <div className="relative flex min-w-0 flex-1 flex-col gap-3">
          <div className="flex items-center gap-4">
            <p className="mr-auto truncate text-h6 text-black">
              {displayName || name}
            </p>
            <p className="flex h-[22px] items-center rounded-2xl bg-primary-subtle px-2 text-footnote text-black">
              Installed
            </p>
          </div>
          <div className="flex gap-6 overflow-hidden">
            <div className="flex flex-col gap-1">
              <p className="text-footnote capitalize text-neural-04">
                Template name
              </p>
              <p className="flex items-center gap-2 truncate">{templateName}</p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-footnote capitalize text-neural-04">Status</p>
              <p className="flex items-center gap-2 truncate capitalize">
                {state}
              </p>
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 flex justify-end gap-3 bg-white opacity-0 transition-opacity before:absolute before:bottom-full before:h-full before:w-full before:bg-gradient-to-t before:from-white before:to-transparent group-hover:opacity-100">
            <Button
              variant="ghost-error"
              loading={loading}
              onClick={handleUninstall}
            >
              Uninstall
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
