import { useContext, useEffect, useRef, useState } from 'react';

import { formatModelName } from '@utils';

import { AppConstructorNavigationContext } from '@components/Providers';

type Props = {
  title: string;
  children?: React.ReactNode;
};

export const JobConstructorSection = ({ title, children }: Props) => {
  const { registerNavigationTitle } = useContext(
    AppConstructorNavigationContext,
  );

  const [number, setNumber] = useState(1);

  const ref = useRef<HTMLDivElement>(null);

  const name = formatModelName(title);

  useEffect(() => {
    if (title) {
      registerNavigationTitle(title);
    }
  }, [title, registerNavigationTitle]);

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const index = Array.from(element.parentElement!.children).indexOf(element);

    setNumber(index + 1);
  }, []);

  return (
    <div className="flex flex-col gap-6" ref={ref}>
      <div id={name} className="flex items-start gap-3">
        <span className="flex h-9 w-9 shrink-0 translate-y-[5px] items-center justify-center rounded-full bg-primary text-white">
          {number}
        </span>
        <p className="text-h4">{title}</p>
      </div>
      {children}
    </div>
  );
};
