import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState, PlatformAppLoader } from '@typings';

type State = AppState.PlatformApps['loaders'];

const initialState: State = {
  templates: false,
  schema: false,
  instances: false,
};

export const platformAppsLoadersSlice = createSlice({
  name: 'platform-apps-loaders',
  initialState,
  reducers: {
    setPlatformAppsLoader: (state, action: PayloadAction<Partial<State>>) => {
      Object.entries(action.payload).forEach(([loader, value]) => {
        state[loader as PlatformAppLoader] = value;
      });
    },
  },
});

export const { setPlatformAppsLoader } = platformAppsLoadersSlice.actions;

export default platformAppsLoadersSlice.reducer;
