import { Controller, useFormContext } from 'react-hook-form';
import { faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

import { isFunction } from '@utils';

import { Button, Field, Icon, Modal } from '@components';

import { JobConstructorSecretModal } from '../Modals';

type Props = {
  name: string;
  required?: boolean;
  label?: string;
  note?: string;
  onChange?: (token: string) => void;
};

export const AppHuggingFaceTokenField: React.FC<Props> = ({
  name,
  label,
  note,
  required,
  onChange,
}) => {
  const { control, setValue } = useFormContext();

  const handleSecretAdd = ({ value }: { value: string }) => {
    if (isFunction(onChange)) {
      onChange(value);
    } else {
      setValue(name, value, { shouldValidate: true });
    }
  };

  return (
    <div className="flex w-full">
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          const isSecretAdded = !!field.value;
          return (
            <Field.Input
              {...field}
              disabled
              required={required}
              containerClassName="flex-1"
              label={label}
              className={clsx('w-full rounded-r-none !text-rebecca', {
                'pl-9': isSecretAdded,
              })}
              labelClassName="!text-neural-04"
              note={note}
              error={error?.message}
            >
              {isSecretAdded && (
                <div slot="icon" className="absolute left-3 top-5">
                  <Icon icon={faLockKeyhole} className="text-neural-04" />
                </div>
              )}
            </Field.Input>
          );
        }}
      />
      <Modal
        content={
          <JobConstructorSecretModal
            isNameRequired={false}
            onAdd={handleSecretAdd}
          />
        }
      >
        <Button className="h-14 rounded-l-none capitalize">
          Choose secret
        </Button>
      </Modal>
    </div>
  );
};
