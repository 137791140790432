import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@typings';

type Template = AppState.PlatformApps['templates'][number];

type State = {
  fetched: boolean;
  appName: Template['name'];
  templates: Template[];
};

const initialState: State = {
  fetched: false,
  appName: '',
  templates: [],
};

export const platformAppsVersionsSlice = createSlice({
  name: 'platform-apps-versions',
  initialState,
  reducers: {
    platformAppsVersionsRequest: (state) => {
      state.fetched = false;
    },
    setPlatformAppsVersions: (
      state,
      action: PayloadAction<Pick<State, 'appName' | 'templates'>>,
    ) => {
      const { appName, templates } = action.payload;

      state.fetched = true;
      state.appName = appName;
      state.templates = templates;
    },
    resetPlatformAppsVersions: () => initialState,
  },
});

export const {
  platformAppsVersionsRequest,
  setPlatformAppsVersions,
  resetPlatformAppsVersions,
} = platformAppsVersionsSlice.actions;

export default platformAppsVersionsSlice.reducer;
