type Options = {
  pattern: any;
};

/**
 * Drops object nullable properties
 *
 * @example removeEmptyProperties({ foo: "foo", bar: null, baz: undefined, quax: "" });
 * // return { foo: "foo", quax: "" }
 *
 * @example removeEmptyProperties({ foo: "foo", quax: "" }, { pattern: "" });
 * // return { foo: "foo" }
 */
export const removeEmptyProperties = <Value>(
  object: Record<string, Value>,
  { pattern }: Options = {} as Options,
): Record<string, Value> =>
  Object.fromEntries(
    Object.entries(object).filter(
      ([, value]) => value != null && value !== pattern,
    ),
  );

/**
 * Deeply removes empty properties from an object
 * while preserving arrays.
 *
 * @example
 * removeEmptyPropertiesDeep({ foo: "", nested: { bar: null, baz: "ok" } });
 * // returns { nested: { baz: "ok" } }
 *
 * @example
 * removeEmptyPropertiesDeep({ foo: ["", "hello", null, undefined] }, { pattern: "" });
 * // returns { foo: ["hello"] }
 */
export const removeEmptyPropertiesDeep = <Value>(
  object: Record<string, Value>,
  options: Options = {} as Options,
): Record<string, Value> => {
  const cleanedObject = removeEmptyProperties(object, options);

  return Object.fromEntries(
    Object.entries(cleanedObject)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          // Recursively clean array elements
          const filteredArray = value
            .map((item) =>
              item && typeof item === 'object'
                ? removeEmptyPropertiesDeep(item, options)
                : item,
            )
            .filter((item) => item != null && item !== options.pattern);

          return filteredArray.length ? [key, filteredArray] : undefined;
        }

        if (typeof value === 'object' && value !== null) {
          // @ts-expect-error todo: fix type
          const nestedObject = removeEmptyPropertiesDeep(value, options);
          return Object.keys(nestedObject).length
            ? [key, nestedObject]
            : undefined;
        }

        return [key, value];
      })
      .filter((entry): entry is [string, Value] => entry !== undefined),
  );
};
