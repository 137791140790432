import { combineReducers } from 'redux';

import organizationCreditsReducer from './slices/cache/organizationCredits.slice';
import organizationInvitesReducer from './slices/cache/organizationInvites.slice';
import permissionsReducer from './slices/cache/permissions.slice';
import platformAppsVersionsReducer from './slices/cache/platformApps/platformAppsCache/platformAppsVersions.slice';
import platformAppsFetchers from './slices/cache/platformApps/platformAppsFetcher.slice';
import platformAppsInstances from './slices/cache/platformApps/platformAppsInstances.slice';
import platformAppsLoaders from './slices/cache/platformApps/platformAppsLoaders.slice';
import platformAppsSchemas from './slices/cache/platformApps/platformAppsSchemas.slice';
import platformAppsTemplates from './slices/cache/platformApps/platformAppsTemplates.slice';
import userClustersReducer from './slices/cache/userClusters.slice';
import viewTypeReducer from './slices/cache/viewType.slice';
import configReducer from './slices/config.slice';
import contextReducer from './slices/context.slice';
import userReducer from './slices/user.slice';

export const rootReducer = combineReducers({
  config: configReducer,
  context: contextReducer,
  user: userReducer,
  cache: combineReducers({
    platformApps: combineReducers({
      fetchers: platformAppsFetchers,
      instances: platformAppsInstances,
      loaders: platformAppsLoaders,
      templates: platformAppsTemplates,
      schemas: platformAppsSchemas,
      cache: combineReducers({
        versions: platformAppsVersionsReducer,
      }),
    }),
    organizationCredits: organizationCreditsReducer,
    organizationInvites: organizationInvitesReducer,
    permissions: permissionsReducer,
    userClusters: userClustersReducer,
    viewType: viewTypeReducer,
  }),
});
