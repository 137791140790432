import { z, ZodTypeAny } from 'zod';

import { PlatformAppUiSchema } from '@typings';
import { as, conditionalProperty } from '@utils';

type JSONSchema = {
  type?: string;
  properties?: Record<string, JSONSchema>;
  required?: string[];
  default?: any;
  $defs: Record<string, JSONSchema>;
};

type RequiredSchema = Record<string, boolean>;

type SchemaContext = {
  parent: string;
  requiredSchema: RequiredSchema;
};

/* eslint-disable no-restricted-syntax */
type Schema = Record<string, any>;

type ExtractedSchema = Record<string, PlatformAppUiSchema.ModelSchema>;

type NestResolver = {
  nest: PlatformAppUiSchema.NestResolver;
  schema: Schema;
  level: number;
  type: string;
  parent?: string;
  fields?: string[];
};

type Arguments = {
  schema: Schema;
  root: JSONSchema;
  context: SchemaContext;
  nest: PlatformAppUiSchema.NestResolver;
  typeName: string;
  parentType?: string;
  prefix?: string;
};

function resolveRef(
  ref: string,
  schema: JSONSchema,
): { type: string; schema: JSONSchema } {
  const type = ref.replace(/^#\/\$defs\//, '');

  return { schema: schema.$defs[type], type };
}

function resolveNestedness({
  nest,
  schema,
  level,
  type,
  fields = [],
  parent,
}: NestResolver) {
  const { title, description } = schema;

  nest.level = level;

  if (title) {
    nest.list.push({
      ...conditionalProperty('parent', parent),
      title,
      description,
      level,
      fields,
      type,
    });
  }

  if (nest) return nest;
}

function extractProperties({
  schema,
  root,
  context,
  prefix,
  nest,
  typeName,
  parentType,
}: Arguments): PlatformAppUiSchema.ModelSchema {
  const result: PlatformAppUiSchema.ModelSchema = {};

  const nestLevel = nest.level + 1;
  const nestFields = [];

  const setNest = () => {
    nest.level = nestLevel;
  };

  setNest();

  if (schema.anyOf) {
    schema =
      schema.anyOf.find((s: any) => s.$ref) ||
      schema.anyOf.find((s: any) => s.type && s.type !== 'null');

    if (!schema) {
      return result;
    }
  }

  if (schema.$ref) {
    schema = resolveRef(schema.$ref, root).schema;
  }

  if (schema.properties) {
    const { parent } = context;

    for (const [key, propSchema] of Object.entries<any>(schema.properties)) {
      const fullKey = prefix ? `${prefix}.${key}` : key;
      const ancestorKey = prefix ? `${parent}.${prefix}` : parent;
      const contextKey = `${parent}.${fullKey}`;
      const isAncestorRequired = !!context.requiredSchema[ancestorKey];

      const required: boolean = isAncestorRequired
        ? schema.required?.includes(key) ?? false
        : false;

      context.requiredSchema[contextKey] = required;

      if (propSchema.$ref) {
        const { type, schema: resolvedSchema } = resolveRef(
          propSchema.$ref,
          root,
        );

        setNest();

        if (resolvedSchema) {
          Object.assign(
            result,
            extractProperties({
              schema: resolvedSchema,
              root,
              context,
              prefix: fullKey,
              nest,
              typeName: type,
              parentType: typeName,
            }),
          );
        }
      } else if (propSchema.anyOf) {
        const filteredSchema =
          propSchema.anyOf.find((s: any) => s.$ref) ||
          propSchema.anyOf.find((s: any) => s.type && s.type !== 'null');

        setNest();

        if (filteredSchema.$ref) {
          const { type } = resolveRef(filteredSchema.$ref, root);

          Object.assign(
            result,
            extractProperties({
              schema: filteredSchema,
              root,
              context,
              prefix: fullKey,
              nest,
              typeName: type,
              parentType: typeName,
            }),
          );
        } else if (filteredSchema.type) {
          const { anyOf, ...data } = propSchema;

          nestFields.push(contextKey);

          result[fullKey] = {
            ...data,
            ...filteredSchema,
            required,
            default:
              filteredSchema.const ?? filteredSchema.default ?? undefined,
          };
        }
      } else if (propSchema.type) {
        nestFields.push(contextKey);

        result[fullKey] = {
          ...propSchema,
          default: propSchema.const ?? propSchema.default ?? undefined,
          required,
        };
      }
    }
  }

  setNest();
  resolveNestedness({
    nest,
    schema,
    level: nest.level,
    fields: nestFields,
    type: typeName,
    parent: parentType,
  });

  return result;
}

export function extractSchema(jsonSchema: JSONSchema): {
  uiNestSchemas: PlatformAppUiSchema.NestSchema[][];
  params: ExtractedSchema;
} {
  if (!jsonSchema.properties) {
    return {
      uiNestSchemas: [],
      params: {},
    };
  }

  const requiredSchema = as
    .a<string[]>(jsonSchema.required)
    .reduce<RequiredSchema>(
      (schema, field) => ({ ...schema, [field]: true }),
      {},
    );

  const nestSchema: PlatformAppUiSchema.NestResolver[] = [];

  const params = Object.fromEntries(
    Object.entries(jsonSchema.properties).map(([key, schema]) => {
      const context: SchemaContext = {
        parent: key,
        requiredSchema,
      };
      const nest: PlatformAppUiSchema.NestResolver = {
        level: 0,
        list: [],
      };

      const values = [
        key,
        extractProperties({
          schema,
          root: jsonSchema,
          context,
          nest,
          typeName: key,
        }),
      ];

      nestSchema.push(nest);

      return values;
    }),
  );

  const formattedUiNestSchema: PlatformAppUiSchema.NestSchema[][] =
    nestSchema.map(({ list }) => list.sort((a, b) => a.level - b.level));

  return { uiNestSchemas: formattedUiNestSchema, params };
}

function buildNestedSchema(
  obj: Record<string, any>,
): Record<string, ZodTypeAny> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      value instanceof z.ZodType ? value : z.object(buildNestedSchema(value)),
    ]),
  );
}

export function makeAppSchema(definition: Record<string, any>) {
  const schemaShape: Record<string, any> = {};

  Object.entries(definition).forEach(([key, value]) => {
    if (!value.type) return; // Skip if no type is provided

    const { required } = value;
    let fieldSchema: ZodTypeAny;

    switch (value.type) {
      case 'string':
        fieldSchema = required ? z.string().min(1) : z.string();
        break;
      case 'boolean':
        fieldSchema = z.boolean();
        break;
      case 'array':
        fieldSchema = z.array(z.unknown());
        break;
      default:
        fieldSchema = z.unknown();
    }

    if (!value.required) {
      fieldSchema = fieldSchema.optional();
    }

    if (
      value.default !== null &&
      value.default !== undefined &&
      value.default !== ''
    ) {
      fieldSchema = fieldSchema.default(value.default);
    }

    // Handle dot-separated keys to create nested objects
    const keys = key.split('.');
    let currentSchema = schemaShape;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < keys.length - 1; i++) {
      const nestedKey = keys[i];
      if (!(nestedKey in currentSchema)) {
        currentSchema[nestedKey] = {};
      }
      currentSchema = currentSchema[nestedKey];
    }

    currentSchema[keys[keys.length - 1]] = fieldSchema;
  });

  return z
    .object(buildNestedSchema(schemaShape))
    .merge(z.object({ displayName: z.string() }));
}
