import { cn, tv, VariantProps } from '@utils';

import { Render } from '@components/Shared';
import { Logo } from '@components/Ui';

const variants = tv({
  base: 'flex items-center shrink-0 p-2.5 justify-center rounded-xl bg-black',
  variants: {
    size: {
      lg: 'h-28 w-28',
      md: 'h-14 w-14',
      sm: 'h-12 w-12',
    },
    padding: {
      true: 'p-2.5',
      false: 'p-1',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

type Props = VariantProps<typeof variants> & {
  className?: string;
  src?: string;
};

export const PlatformAppLogo: React.FC<Props> = ({ className, src, size }) => {
  const withDefaultLogo = !src;

  return (
    <div
      className={cn(variants({ size, padding: withDefaultLogo }), className)}
    >
      <Render if={src}>
        <img src={src} alt="" />
      </Render>
      <Render if={!src}>
        <Logo.Icon color={false} className="text-white" />
      </Render>
    </div>
  );
};
