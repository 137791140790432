import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@typings';

type State = AppState.PlatformApps['instances'];

const initialState: State = [];

export const platformAppsInstancesSlice = createSlice({
  name: 'platform-apps-instances',
  initialState,
  reducers: {
    setPlatformAppsInstances: (state, action: PayloadAction<State>) =>
      action.payload,
    resetPlatformAppsInstances: () => initialState,
  },
});

export const { setPlatformAppsInstances, resetPlatformAppsInstances } =
  platformAppsInstancesSlice.actions;

export default platformAppsInstancesSlice.reducer;
