import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@typings';

type State = AppState.PlatformApps['schemas'];

const initialState: State = {} as State;

export const platformAppsSchemasSlice = createSlice({
  name: 'platform-apps-schemas',
  initialState,
  reducers: {
    setPlatformAppsSchemas: (state, action: PayloadAction<State>) =>
      action.payload,
  },
});

export const { setPlatformAppsSchemas } = platformAppsSchemasSlice.actions;

export default platformAppsSchemasSlice.reducer;
