import { isArray, isObject } from './typeof';

export const flattenObject = (
  obj: Record<string, any>,
): Record<string, any> => {
  const result: Record<string, any> = {};

  const helper = (item: any, currentPath: string) => {
    if (item !== null && isObject(item) && !isArray(item)) {
      /**
       * Handle plain objects
       */
      Object.keys(item).forEach((key) => {
        // @ts-ignore
        helper(item[key], currentPath ? `${currentPath}.${key}` : key);
      });
    } else if (Array.isArray(item)) {
      /**
       * Handle arrays
       */
      item.forEach((value, index) => {
        helper(value, `${currentPath}.${index}`);
      });
    } else {
      /**
       * Primitive values
       */
      result[currentPath] = item;
    }
  };

  helper(obj, '');
  return result;
};

export const unflattenObject = (
  obj: Record<string, any>,
): Record<string, any> => {
  const result: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    const keys = key.split('.');
    let current = result;

    keys.forEach((part, index) => {
      const nextPart = keys[index + 1];
      const isNextArray = !Number.isNaN(Number(nextPart));

      if (index === keys.length - 1) {
        current[part] = obj[key];
      } else {
        if (!(part in current)) {
          current[part] = isNextArray ? [] : {};
        }
        current = current[part];
      }
    });
  });

  return result;
};
