import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import { PlatformApp } from '@typings';

import { Button, Icon, Render } from '@components/Shared';

import '@splidejs/react-splide/css';

import '../../../styles/splide.css';

type Assets = PlatformApp.Template['assets'];

type Props = {
  assets: Assets;
};

export const PlatformAppAssets: React.FC<Props> = ({ assets }) => {
  const makeSlide = ({ type, url }: Assets[number], index: number) => (
    <SplideSlide key={index}>
      <Render if={type === 'image'}>
        <img src={url} alt="" className="h-60 w-full rounded-lg object-cover" />
      </Render>
      <Render if={type === 'video'}>
        <video
          muted
          controls
          src={url}
          className="h-60 w-full rounded-lg object-cover"
        />
      </Render>
    </SplideSlide>
  );

  return (
    <div className="relative">
      <Splide
        hasTrack={false}
        options={{
          height: 240,
          pagination: false,
          perPage: 3,
          perMove: 1,
          drag: 'free',
          gap: '16px',
          autoHeight: true,
          autoplay: false,
          arrows: assets.length > 3,
        }}
        className="relative"
      >
        <SplideTrack className="relative">
          {assets.filter(({ type }) => type !== 'video').map(makeSlide)}
        </SplideTrack>

        <div className="splide__arrows relative">
          <Button
            theme={false}
            className="splide__arrow splide__arrow--prev left-3"
          >
            <Icon icon={faChevronRight} className="h-12 w-12 text-[40px]" />
          </Button>
          <Button
            theme={false}
            className="splide__arrow splide__arrow--next right-3"
          >
            <Icon icon={faChevronRight} className="h-12 w-12 text-[40px]" />
          </Button>
        </div>
      </Splide>
    </div>
  );
};
