import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@typings';

type State = AppState.PlatformApps['templates'];

const initialState: State = [];

export const platformAppsTemplatesSlice = createSlice({
  name: 'platform-apps-templates',
  initialState,
  reducers: {
    setPlatformAppsTemplates: (state, action: PayloadAction<State>) =>
      action.payload,
    resetPlatformAppsTemplates: () => initialState,
  },
});

export const { setPlatformAppsTemplates, resetPlatformAppsTemplates } =
  platformAppsTemplatesSlice.actions;

export default platformAppsTemplatesSlice.reducer;
