import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { PATH } from '@constants';
import { capitalize } from '@utils';

import { Helmet, Render } from '@components';
import { Layout } from '@components/Layouts';
import { AppsTabs } from '@components/Ui';
import { AppsViewType } from '@components/Ui/Apps';

export const PlatformAppsLayout = () => {
  const { pathname } = useLocation();

  const [title, setTitle] = useState('');

  const formattedTitle = capitalize(title);
  const isAllAppsPage = pathname === PATH.APPS;

  return (
    <Layout title={formattedTitle}>
      <Helmet title={formattedTitle} />
      <Layout.Content>
        <div className="mb-14 flex items-center justify-between">
          <AppsTabs setTitle={setTitle} />
          <Render if={!isAllAppsPage}>
            <AppsViewType />
          </Render>
        </div>
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};
