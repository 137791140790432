import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';

import { isBoolean } from '@utils';

import { Icon, Link, Render } from '@components';

type Props = {
  external?: boolean;
  title?: string;
  to: string;
  icon?: IconProp | true;
};

export const PlatformAppItemLink: React.FC<Props> = ({
  external,
  to,
  icon,
  title = to,
}) => {
  return (
    <Link
      external
      blank
      theme
      to={to}
      className="flex items-center font-600 text-primary transition-colors hover:text-primary-dark"
    >
      <Render if={icon}>
        <Icon
          icon={isBoolean(icon) ? faLink : icon}
          className="mr-3 text-[20px] text-rebecca"
        />
      </Render>
      <span>{title}</span>
      <Render if={external}>
        <Icon
          icon={faArrowUpRightFromSquare}
          className="ml-1 h-5 w-5 text-[14px]"
        />
      </Render>
    </Link>
  );
};
